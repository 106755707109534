import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18')
];

export const server_loads = [0];

export const dictionary = {
		"/(pages)": [~10,[3]],
		"/(admin)/admin": [~5,[2]],
		"/(admin)/admin/imageChooser": [~6,[2]],
		"/(admin)/admin/r/[id]": [~7,[2]],
		"/(pages)/(recipe)/c/[authorSlug]": [~11,[3]],
		"/(pages)/(recipe)/c/[authorSlug]/[recipeSlug]": [~12,[3,4]],
		"/(pages)/(seo)/d/creators": [~13,[3]],
		"/(pages)/(seo)/d/creators/[slug]": [~14,[3]],
		"/(pages)/(seo)/d/recipes": [~15,[3]],
		"/(admin)/login": [8,[2]],
		"/(pages)/recipes": [~16,[3]],
		"/(admin)/signup": [9,[2]],
		"/(pages)/submit-request": [~17,[3]],
		"/[...path]": [~18]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,

	reroute: (() => {})
};

export { default as root } from '../root.js';